const NotApplyToSectionPart2Pluss = () => {
  return (
    <ul>
      <li>
        Kroniske sykdommer og plager der behandling som ifølge medisinsk
        erfaring ikke forventes å kunne helbrede eller vesentlig og varig kunne
        forbedre din tilstand.
      </li>
      <li>
        Meldepliktig sykdom som er regulert av lov eller konsekvenser av en slik
        sykdom
      </li>
      <li>
        Kosmetiske behandlinger og operasjoner eller følger derav, om ikke disse
        er en følge av erstatningsberettiget sykdom eller skade.
      </li>
      <li>
        Utredning, behandling eller operasjon knyttet til sterilisering, abort,
        prevensjon, graviditet, fødsel, familieplanlegging/ ufrivillig
        barnløshet eller kjønnsskifte samt følger av slik behandling.
      </li>
      <li>
        Utredning og behandling av nevropsykiatrisk lidelse (f.eks ADHD,
        autisme, bipolar lidelse).
      </li>
      <li>
        Forsikringen omfatter ikke utredning, behandling eller operasjon knyttet
        til overvekt eller lipødem, herunder dietter, vektregulering og
        fedmeoperasjon, samt følger av slik behandling.
      </li>
      <li>
        Utredning, behandling og operasjon av søvnproblemer/ sykdommer som
        snorking, søvnapné, samt tilknyttet medisinsk utstyr.
      </li>
      <li>
        Utredning og behandling hos tannlege eller kjeveortoped/kjevekirurg.
      </li>
    </ul>
  );
};

export default NotApplyToSectionPart2Pluss;
