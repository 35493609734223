import { useEffect, useState } from 'react';
import { Page, PageWrapper } from '@eir/letter';
import { useRouteMatch } from 'react-router-dom';
import { getOsaPolicyService, useApiRequest } from '@eir/services';

import PageContainer from '../../components/PageContainer';
import PageFooter from '../../components/PageFooter';
import PageHeader from '../../components/PageHeader';
import NewPolicyPage1 from './components/base/NewPolicyPage1';
import NewPolicyPage2 from './components/base/NewPolicyPage2';
import NewPolicyPage3 from './components/base/NewPolicyPage3';
import NewPolicyPage4 from './components/base/NewPolicyPage4';
import NewPolicyPage5 from './components/base/NewPolicyPage5';
import NewPolicyPage6 from './components/base/NewPolicyPage6';
import RenewedPolicyPage1 from './components/base/RenewedPolicyPage1';
import RenewedPolicyPage2 from './components/base/RenewedPolicyPage2';
import RenewedPolicyPage3 from './components/base/RenewedPolicyPage3';
import RenewedPolicyPage4 from './components/base/RenewedPolicyPage4';
import RenewedPolicyPage5 from './components/base/RenewedPolicyPage5';
import RenewedPolicyPage6 from './components/base/RenewedPolicyPage6';
import RenewedPolicyPage7 from './components/base/RenewedPolicyPage7';
import NewPolicyPage1Pluss from './components/pluss/NewPolicyPage1Pluss';
import NewPolicyPage2Pluss from './components/pluss/NewPolicyPage2Pluss';
import NewPolicyPage3Pluss from './components/pluss/NewPolicyPage3Pluss';
import NewPolicyPage4Pluss from './components/pluss/NewPolicyPage4Pluss';
import NewPolicyPage5Pluss from './components/pluss/NewPolicyPage5Pluss';

const InsuranceLetterNorwayView = () => {
  const [documentName, setDocumentName] = useState('');

  const match = useRouteMatch('/p/:policyId');

  console.log('match', match.params.policyId);
  const { data } = useApiRequest(
    getOsaPolicyService('AvonovaAPI', match.params.policyId)
  );

  useEffect(() => {
    if (data) {
      setDocumentName(
        data.answers.product === 'avonova-no-base'
          ? 'Forsikringsbevis 2.9 Base'
          : 'Forsikringsbevis 2.9 Pluss'
      );
    }
  }, [data]);

  return (
    data &&
    (data.answers.product === 'avonova-no-base' ? (
      <PageWrapper>
        {/* {data?.previousPolicyId ? (
          <>
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage1 data={data} />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={1} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage2 data={data} />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={2} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage3 data={data} />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={3} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage4 data={data} />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={4} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage5 />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={5} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage6 />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={6} />
              }
            />
            <Page
              pageHeader={<PageHeader />}
              content={
                <PageContainer>
                  <RenewedPolicyPage7 />
                </PageContainer>
              }
              pageFooter={
                <PageFooter documentName={documentName} pageNumber={7} />
              }
            />
          </>
        ) : ( */}
        <>
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage1 data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={1} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage2 data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={2} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage3 data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={3} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage4 />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={4} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage5 />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={5} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage6 />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={6} />
            }
          />
        </>
        {/* )} */}
      </PageWrapper>
    ) : (
      <PageWrapper>
        {/* {data?.previousPolicyId ? (
        <>
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <RenewedPolicyPage1 data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={1} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <RenewedPolicyPage2 data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={2} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <RenewedPolicyPage3 data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={3} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <RenewedPolicyPage4 data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={4} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <RenewedPolicyPage5 />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={5} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <RenewedPolicyPage6 />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={6} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <RenewedPolicyPage7 />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={7} />
            }
          />
        </>
      ) : ( */}
        <>
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage1Pluss data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={1} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage2Pluss data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={2} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage3Pluss data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={3} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage4Pluss data={data} />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={4} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage5Pluss />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={5} />
            }
          />
          <Page
            pageHeader={<PageHeader />}
            content={
              <PageContainer>
                <NewPolicyPage6 />
              </PageContainer>
            }
            pageFooter={
              <PageFooter documentName={documentName} pageNumber={6} />
            }
          />
        </>
        {/* )} */}
      </PageWrapper>
    ))
  );
};

export default InsuranceLetterNorwayView;
