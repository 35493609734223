import BenefitTaxationSection from '../BenefitTaxationSection';
import NotApplyToSectionPart2Pluss from './NotApplyToSectionPart2Pluss';
import NotCoveredCostsSectionPluss from './NotCoveredCostsSectionPluss';
import PersonalInformationSection from '../PersonalInformationSection';

const NewPolicyPage4Pluss = () => {
  return (
    <>
      <NotApplyToSectionPart2Pluss />
      <NotCoveredCostsSectionPluss />
      <BenefitTaxationSection />
      <PersonalInformationSection />
    </>
  );
};

export default NewPolicyPage4Pluss;
