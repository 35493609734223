const NotCoveredCostsSectionPluss = () => {
  return (
    <>
      <h2>Forsikringen dekker ikke disse kostnadene:</h2>
      <ul>
        <li>Losji i forbindelse med medisinsk behandling</li>
        <li>Tapt arbeidsinntekt</li>
        <li>
          Kostnader i forbindelse med sent avbestilte eller uteblitte avtaler,
          behandlinger eller operasjoner
        </li>
      </ul>
    </>
  );
};

export default NotCoveredCostsSectionPluss;
