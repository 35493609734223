import InformationSectionPart1 from '../InformationSectionPart1';

const NewPolicyPage5 = () => {
  return (
    <>
      <InformationSectionPart1 />
    </>
  );
};

export default NewPolicyPage5;
