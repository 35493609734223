import styled from 'styled-components';

const PersonalInformationSection = () => {
  return (
    <>
      <h2>Personopplysninger</h2>
      <p>
        Vi beskytter personopplysningene dine og behandler dem i samsvar med
        databeskyttelsesforordningen (GDPR) og andre gjeldende regler. For mer
        informasjon om forsikringsgiverens behandling av personopplysninger, les
        mer i forsikringsselskapets retningslinjer for personvern her:{' '}
        <Link href='https://www.eirforsakring.com/sv/integritetspolicy'>
          Eir Försäkrings ABs retningslinjer for personvern
          (www.eirforsakring.com)
        </Link>
        . For mer informasjon om Avonovas behandling av personopplysninger, les
        mer i Avonovas retningslinjer for personvern her:
        <Link href='https://www.avonova.no/om-avonova-helse/personvern-digitale-kanaler/'>
          https://www.avonova.no/om-avonova-helse/personvern-digitale-kanaler/
        </Link>
      </p>
    </>
  );
};

export default PersonalInformationSection;

const Link = styled.a`
  color: #26dcba;
  text-decoration: underline;
`;
