import styled from 'styled-components';

const InformationSectionPart2 = () => {
  return (
    <>
      <ul>
        <li>
          Forbrukertilsynet Besøksadresse: Porselensvegen 32, 3920 Porsgrunn.
          Postadresse: Postboks 2862 Kjørbekk, 3702 Skien. Tlf.: 23 400 600
          https://www.forbrukertilsynet.no/
        </li>
        <li>
          Integritetsbeskyttelsesmyndigheten
          (Integritetsbeskyttelsesmyndigheten, Box 8114, 104 20 Stockholm{' '}
          <Link href='mailto:imy@imy.se'>imy@imy.se</Link>) og Datatilsynet (
          <Link href='https://www.datatilsynet.no/'>
            https://www.datatilsynet.no/
          </Link>
          ) hva gjelder behandling av personopplysninger.
        </li>
      </ul>
    </>
  );
};

export default InformationSectionPart2;

const Link = styled.a`
  color: #26dcba;
  text-decoration: underline;
`;
