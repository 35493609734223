const HealthCareGuaranteeSectionPart1Pluss = () => {
  return (
    <>
      <h2>Garantitider som gjelder for forsikringen</h2>
      <p>
        Fra det tidspunktet forsikringstilfellet ditt er meldt inn til og
        godkjent av forsikringsselskapet har du en garanti:
      </p>
      <ul>
        <li>
          en første medisinsk rådgivning av relevant behandlingsspesialist innen
          syv (7) arbeidsdager, og
        </li>
        <li>
          operasjonen skal finne sted innen tyve (20) arbeidsdager fra
          operasjonen er godkjent av forsikringsgiveren.
        </li>
      </ul>
    </>
  );
};

export default HealthCareGuaranteeSectionPart1Pluss;
