import styled from 'styled-components';

const ComplainSection = () => {
  return (
    <section>
      <h2>Klagemuligheter</h2>
      <p>
        Dersom du er misfornøyd med vurdering i din sak, så ta alltid først
        kontakt med din saksbehandler. En tydelig begrunnelse kan gi utfyllende
        opplysninger og eventuelle misforståelser kan oppklares. Dersom du
        likevel ikke er tilfreds med avgjørelsen og ønsker å klage på denne så
        må en slik klage settes frem på klageskjemaet som er tilgjengelig på vår
        nettside{' '}
        <Link href='https://www.avonova.no/helseforsikring/helseforsikringsklager'>
          www.avonova.no/helseforsikring/helseforsikringsklager
        </Link>
        .
      </p>
      <p>
        Avonova`s klageansvarlig behandler klager som fremsettes skriftlig på
        klageavgjørelsen. Klageansvarlig prøver ikke saker som er under prøving
        i domstol, rettsmekling, eller i annen ekstern ordning, f.eks. nemnd –
        og heller ikke saker hvor tvisten i saken har vært prøvet i noen av
        disse instanser. Klageansvarlig arbeider upartisk og selvstendig.
        Informasjon om hvordan du fremstiller en klage til klageansvarlig er
        tilgjengelig på vår nettside{' '}
        <Link href='https://www.avonova.no/helseforsikring/helseforsikringsklager'>
          www.avonova.no/helseforsikring/helseforsikringsklager
        </Link>
        .
      </p>
      <p>
        Finansklagenemnda (FinKN) er et bransjebasert klageorgan som behandler
        klagesaker mot banker, forsikringsselskap, inkassoselskap, og andre
        finansselskap. Avgjørelser i Finansklagenemda er ikke juridisk bindende.
        Dersom avgjørelsen bestrides av kunde eller forsikringsselskap, må saken
        bringes inn for de ordinære domstoler.
        <br />
        POSTADRESSE: Finansklagenemnda, Pb. 53 Skøyen, 0212 Oslo
        <br />
        TELEFON: 23 13 19 60 (kl. 09.00-11.30 og kl. 12.30-15.00)
        <br />
        E-POST: <Link href='mailto:post@finkn.no'>post@finkn.no</Link>
        <br />
        HJEMMESIDE:{' '}
        <Link href='https://www.finkn.no/'>https://www.finkn.no/</Link>
      </p>
      <p>
        Som ved andre tvister kan du også gå til en domstol. Ta kontakt med
        nærmeste tingrett om du vil vite mer eller se{' '}
        <Link href='https://www.domstol.no'>www.domstol.no</Link>.
      </p>
      <p>
        Forbrukerrådet kan også gi noe veiledning i forbindelse med klage på
        forsikring.
        <br />
        HJEMMESIDE:{' '}
        <Link href='https://www.forbrukerradet.no/forside/okonomi-og-betaling/forsikring/'>
          https://www.forbrukerradet.no/forside/okonomi-og-betaling/forsikring/
        </Link>
      </p>
      <p>
        For ytterligere informasjon om klagemuligheter, se forsikringsvilkårene
        eller vår hjemmeside{' '}
        <Link href='https://www.avonova.no/helseforsikring/helseforsikringsklager'>
          www.avonova.no/helseforsikring/helseforsikringsklager
        </Link>
        .
      </p>
    </section>
  );
};

export default ComplainSection;

const Link = styled.a`
  color: #26dcba;
  text-decoration: underline;
`;
