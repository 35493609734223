import ApplyToWhoSectionPluss from './ApplyToWhoSectionPluss';
import ApplyWhenSection from '../ApplyWhenSection';
import CoverSectionPluss from './CoverSectionPluss';
import HealthCareGuaranteeSectionPart1Pluss from './HealthCareGuaranteeSectionPart1Pluss';
import ApplyWhereSectionPluss from './ApplyWhereSectionPluss';

const NewPolicyPage2Pluss = ({ data }) => {
  return (
    <>
      <ApplyToWhoSectionPluss />
      <ApplyWhenSection />
      <ApplyWhereSectionPluss />
      <CoverSectionPluss />
      <HealthCareGuaranteeSectionPart1Pluss />
    </>
  );
};

export default NewPolicyPage2Pluss;
