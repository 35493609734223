import styled from 'styled-components';

const HowToUseSection = () => {
  return (
    <>
      <h2>Hvordan du bruker forsikringen</h2>
      <p>
        Hvis du har behov for å melde inn sak gjøres dette gjennom: Digital
        registrering av HR/Leder via skjema eller Digital Direkteregistrering av
        den enkelte ansatte på hjemmesiden{' '}
        <Link href='https://www.avonova.no/meld-skade-her'>
          https://www.avonova.no/meld-skade-her
        </Link>
        <br />
        Hør med din leder eller se i deres gruppeavtale hva som gjelder for
        deres avtale.
      </p>
    </>
  );
};

export default HowToUseSection;

const Link = styled.a`
  color: #26dcba;
  text-decoration: underline;
`;
