const GroupRepresentativesAndGroupMembersSection = () => {
  return (
    <section>
      <h2>Grupperepresentant, gruppemedlemmer m.m.</h2>
      <p>
        Eir er forsikringsgiver.
        <br />
        Grupperepresentanten (arbeidsgiver samt forsikringstaker) representerer
        den forsikrede gruppen, dvs. de ansatte så vel som gruppemedlemmene og
        de forsikrede.
        <br />
        Gruppemedlemmene er direkte tilknyttet forsikringen som følge av denne
        gruppeavtalen.
      </p>
    </section>
  );
};

export default GroupRepresentativesAndGroupMembersSection;
