const LimitationsSectionBase = () => {
  return (
    <>
      <h2>Viktige begrensninger i forsikringen</h2>
      <p>
        I forsikringen finnes det ulike begrensninger. Nedenfor er noen av de
        viktigste begrensningene innen forsikringen. Se vilkår for fullstendig
        oversikt.
      </p>
    </>
  );
};

export default LimitationsSectionBase;
