import DeadlineForNotifyingSection from '../DeadlineForNotifyingSection';
import DeducibleSection from '../DeducibleSection';
import HealthCareGuaranteeSectionPart2Pluss from './HealthCareGuaranteeSectionPart2Pluss';
import HowToUseSectionPluss from './HowToUseSectionPluss';
import LimitationsSectionPluss from './LimitationsSectionPluss';
import NotApplyToSectionPart1Pluss from './NotApplyToSectionPart1Pluss';

const NewPolicyPage3Pluss = ({ data }) => {
  return (
    <>
      <HealthCareGuaranteeSectionPart2Pluss />
      <HowToUseSectionPluss />
      <DeadlineForNotifyingSection />
      <DeducibleSection data={data} />
      <LimitationsSectionPluss />
      <NotApplyToSectionPart1Pluss />
    </>
  );
};

export default NewPolicyPage3Pluss;
