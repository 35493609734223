import BenefitTaxationSection from '../BenefitTaxationSection';
import NotApplyToSectionPart2Base from './NotApplyToSectionPart2Base';
import NotCoveredCostsSectionBase from './NotCoveredCostsSectionBase';
import PersonalInformationSection from '../PersonalInformationSection';

const NewPolicyPage4 = () => {
  return (
    <>
      <NotApplyToSectionPart2Base />
      <NotCoveredCostsSectionBase />
      <BenefitTaxationSection />
      <PersonalInformationSection />
    </>
  );
};

export default NewPolicyPage4;
