import ApplyWhereSectionBase from './ApplyWhereSectionBase';
import ApplyToWhoSectionBase from './ApplyToWhoSectionBase';
import ApplyWhenSection from '../ApplyWhenSection';
import CoverSectionBase from './CoverSectionBase';
import HealthCareGuaranteeSectionBase from './HealthCareGuaranteeSectionBase';

const NewPolicyPage2 = () => {
  return (
    <>
      <ApplyToWhoSectionBase />
      <ApplyWhenSection />
      <ApplyWhereSectionBase />
      <CoverSectionBase />
      <HealthCareGuaranteeSectionBase />
    </>
  );
};

export default NewPolicyPage2;
