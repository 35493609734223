const NotApplyToSectionPart1Base = () => {
  return (
    <>
      <h2>Forsikringen gjelder ikke for:</h2>
      <ul>
        <li>
          Behandling av sykdom eller plager som har oppstått eller har blitt
          påvist før forsikringen begynte å gjelde, omfattes ikke av
          forsikringen.
        </li>
        <li>
          Medisinsk utredning eller behandling som ikke har blitt godkjent på
          forhånd av forsikringsselskapet.
        </li>
        <li>
          Akutt sykdom/plager, akutt medisinsk behandling, akutt transport og
          utgifter i forbindelse med akutt medisinsk behandling.
        </li>
        <li>
          Konsultasjon hos allmennlege og spesialist i allmennmedisin. Digital
          legetjeneste eller videokonsultasjon hos en leverandør Avonova har
          avtale med er likevel omfattet.
        </li>
        <li>
          Forsikringen omfatter ikke forebyggende behandling,
          vedlikeholdsbehandlinger, vaksinasjoner, helsesjekk og undersøkelser
          som har helseforebyggende hensikt, herunder undersøkelser i den
          hensikt å avklare risiko for sykdom og screeningundersøkelser.
        </li>
        <li>
          Kroniske sykdommer og plager der behandling som ifølge medisinsk
          erfaring ikke forventes å kunne helbrede eller vesentlig og varig
          kunne forbedre din tilstand.
        </li>
        <li>
          Meldepliktig sykdom som er regulert av lov eller konsekvenser av en
          slik sykdom
        </li>
      </ul>
    </>
  );
};

export default NotApplyToSectionPart1Base;
