import styled from 'styled-components';
import BackgroundAndPurposeSection from './BackgroundAndPurposeSection';
import ContractSection from './ContractSection';
import GroupRepresentativesAndGroupMembersSection from './GroupRepresentativesAndGroupMembersSection';

const Page1 = ({ data }) => {
  return (
    <>
      <H1>
        Gruppeavtale for gruppeforsikring
        <br />
        {data.answers.product === 'avonova-no-base' ? (
          <span>Base</span>
        ) : (
          <span>Pluss</span>
        )}
      </H1>
      <ContractSection data={data} />
      <BackgroundAndPurposeSection />
      <GroupRepresentativesAndGroupMembersSection />
    </>
  );
};

export default Page1;

const H1 = styled.h1`
  font-size: 2.2rem;

  span {
    display: flex;
    justify-content: center;
  }
`;
