const HealthCareGuaranteeSectionBase = () => {
  return (
    <>
      <h2>Garantitider som gjelder for forsikringen</h2>
      <p>
        Fra det tidspunktet forsikringstilfellet ditt er meldt inn til og
        godkjent av forsikringsselskapet har du en garanti:
      </p>
      <ul>
        <li>
          en første medisinsk rådgivning av relevant behandlingsspesialist innen
          syv (7) arbeidsdager, og
        </li>
        <li>
          operasjonen skal finne sted innen tyve (20) arbeidsdager fra
          operasjonen er godkjent av forsikringsgiveren.
        </li>
      </ul>
      <p>
        Garantien gjelder under forutsetning av at forsikringsgiverens
        Behandlingsplanlegging har blitt kontaktet, godkjent behandlingen og at
        den forsikrede er beredt på å måtte reise innenfor Norges grenser.
        Garantien gjelder ikke hvis den forsikrede ikke kan opereres av
        medisinske årsaker, uteblir fra den bestilte behandlingen, nekter
        bestilt tid, eller etter avtale går med på å bestille et senere
        tidspunkt for behandling.{' '}
      </p>
    </>
  );
};

export default HealthCareGuaranteeSectionBase;
