import styled from 'styled-components';

const InformationSectionPart1 = () => {
  return (
    <>
      <h2>
        Informasjon om forsikringsgiveren, forsikringsformidleren og tilsyn m.m.
      </h2>
      <p>
        Forsikringsgiver er Eir Försäkring AB, org.nr. 559166-0617, Kungsgatan
        37, 111 56 Stockholm (forsikringsgiveren). Forsikringsgiveren kan
        kontaktes på{' '}
        <Link href='mailto:info@eirforsakring.se'>info@eirforsakring.se</Link>{' '}
        eller tlf. +47 911 78 000.{' '}
        <Link href='https://www.eirforsakring.com'>
          Webadresse er www.eirforsakring.com
        </Link>
        .
      </p>
      <p>
        Forsikringsformidler er Avonova Insurance AS, org.nr. 926931466,
        Postboks 4903 Nydalen, 0423 Oslo. Forsikringsformidleren kan kontaktes
        på{' '}
        <Link href='mailto:forsikring@avonova.no'>forsikring@avonova.no</Link>.
      </p>
      <p>
        Avonova er en forsikringsformidler tilknyttet Eir Forsikring AB. Eir
        Forsikring AB er ansvarlig for rent formuestap i overensstemmelse med 4
        kap. 16§ lag om försäkringsdistribution (LFD), som kan inntreffe om
        Avonova ikke overholder sine forpliktelser (med forsett eller av
        uaktsomhet). Avonova er registrert i det norske Finanstilsynets
        virksomhetsregister, hvilket kan kontrolleres her:{' '}
        <Link href='https://www.finanstilsynet.no/virksomhetsregisteret/'>
          https://www.finanstilsynet.no/virksomhetsregisteret/
        </Link>
      </p>
      <p>
        Avonova`s ansatte som selger forsikring, får en fast månedslønn
        uavhengig av antall solgte forsikringer. Avonova handler på vegne av
        forsikringsgiveren og er etter avtale forpliktet til å distribuere
        forsikring utelukkende på vegne av forsikringsgiveren. Avonova`s
        kompensasjon for å distribuere forsikringen til forsikringsgiveren
        beregnes på grunnlag av skadeutfall og premievolum. Avonova har ingen
        kvalifisert eierandel i forsikringsgiveren, og forsikringsgiveren har
        ingen kvalifisert eierandel i Avonova.
      </p>
      <p>
        På forespørsel skal forsikringsgiveren gi informasjon om en ansatt i
        Avonova har rett til å distribuere forsikringer og om denne retten er
        begrenset til en bestemt type forsikring, en eller flere
        forsikringsklasser eller grupper av forsikringsklasser.
      </p>
      <p>
        Virksomheten til Eir Forsikring AB og Avonova er under tilsyn av
        relevante myndigheter i Sverige og Norge der de mest relevante er:
      </p>
      <ul>
        <li>
          Finansinspektionen (Finansinspektionen, Box 7821, 103 97 Stockholm
          eller{' '}
          <Link href='mailto:finansinspektionen@fi.se'>
            finansinspektionen@fi.se)
          </Link>{' '}
        </li>
        <li>
          Finanstilsynet (Besøksadresse: Revierstredet 3, 0151 Oslo Postadresse:
          Postboks 1187 Sentrum 0107 Oslo Tlf: 22 93 98 00 E-post:{' '}
          <Link href='mailto:post@finanstilsynet.no'>
            post@finanstilsynet.no
          </Link>{' '}
          https://www.finanstilsynet.no/)
        </li>
        <li>
          Konsumentverket (Konsumentverket, Box 48, 651 02 Karlstad eller{' '}
          <Link href='mailto:konsumentverket@konsumentverket.se'>
            konsumentverket@konsumentverket.se
          </Link>
          ) og Forbrukertilsynet (
          <Link href='https://www.forbrukertilsynet.no/'>
            https://www.forbrukertilsynet.no/
          </Link>
          ) hva gjelder reklame og markedsføring.{' '}
        </li>
      </ul>
    </>
  );
};

export default InformationSectionPart1;

const Link = styled.a`
  color: #26dcba;
  text-decoration: underline;
`;
