import CongratulationsSection from '../CongratulationsSection';
import PolicyInformationSection from '../PolicyInformationSection';

const NewPolicyPage1 = ({ data }) => {
  return (
    <>
      <h1>
        Helseforsikring
        <br />
        Forsikringsbevis - Base
      </h1>
      <PolicyInformationSection data={data} />
      <CongratulationsSection />
    </>
  );
};

export default NewPolicyPage1;
