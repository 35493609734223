const HealthCareGuaranteeSectionPart1Pluss = () => {
  return (
    <p>
      Garantien gjelder under forutsetning av at forsikringsgiverens
      Behandlingsplanlegging har blitt kontaktet, godkjent behandlingen og at
      den forsikrede er beredt på å måtte reise innenfor Norges grenser.
      Garantien gjelder ikke hvis den forsikrede ikke kan opereres av medisinske
      årsaker, uteblir fra den bestilte behandlingen, nekter bestilt tid, eller
      etter avtale går med på å bestille et senere tidspunkt for behandling.
    </p>
  );
};

export default HealthCareGuaranteeSectionPart1Pluss;
