import DeadlineForNotifyingSection from '../DeadlineForNotifyingSection';
import DeducibleSection from '../DeducibleSection';
import HowToUseSectionBase from './HowToUseSectionBase';
import LimitationsSectionBase from './LimitationsSectionBase';
import NotApplyToSectionPart1Base from './NotApplyToSectionPart1Base';

const NewPolicyPage3 = ({ data }) => {
  return (
    <>
      <HowToUseSectionBase />
      <DeadlineForNotifyingSection />
      <DeducibleSection data={data} />
      <LimitationsSectionBase />
      <NotApplyToSectionPart1Base />
    </>
  );
};

export default NewPolicyPage3;
