const HowToUseSectionBase = () => {
  return (
    <>
      <h2>Hvordan du bruker forsikringen</h2>
      <p>
        Hvis du har behov for å melde inn sak gjøres dette gjennom: Digital
        registrering av HR/Leder via skjema eller Digital Direkteregistrering av
        den enkelte ansatte på hjemmesiden https://www.avonova.no/meld-skade-her
        Hør med din leder eller se i deres gruppeavtale hva som gjelder for
        deres avtale.
      </p>
    </>
  );
};

export default HowToUseSectionBase;
