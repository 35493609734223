import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { getOsaQuoteService, useApiRequest } from '@eir/services';
import { Page, PageWrapper } from '@eir/letter';

import Page1 from './components/Page1';
import Page2 from './components/Page2';
import Page3 from './components/Page3';
import PageContainer from '../../components/PageContainer';
import PageFooter from '../../components/PageFooter';
import PageHeader from '../../components/PageHeader';

const GroupContractNorwayView = () => {
  const [documentName, setDocumentName] = useState('');
  const match = useRouteMatch('/q/:quoteId');
  const { data } = useApiRequest(
    getOsaQuoteService('AvonovaAPI', match.params.quoteId)
  );

  useEffect(() => {
    if (data) {
      setDocumentName(
        data.answers.product === 'avonova-no-base'
          ? 'Gruppeavtale 2.9 Base'
          : 'Gruppeavtale 2.9 Pluss'
      );
    }
  }, [data]);

  return (
    data && (
      <PageWrapper>
        <Page
          pageHeader={<PageHeader />}
          content={
            <PageContainer>
              <Page1 data={data} />
            </PageContainer>
          }
          pageFooter={<PageFooter documentName={documentName} pageNumber={1} />}
        />
        <Page
          pageHeader={<PageHeader />}
          content={
            <PageContainer>
              <Page2 data={data} />
            </PageContainer>
          }
          pageFooter={<PageFooter documentName={documentName} pageNumber={2} />}
        />
        <Page
          pageHeader={<PageHeader />}
          content={
            <PageContainer>
              <Page3 data={data} />
            </PageContainer>
          }
          pageFooter={<PageFooter documentName={documentName} pageNumber={3} />}
        />
      </PageWrapper>
    )
  );
};

export default GroupContractNorwayView;
