const ApplyWhereSectionBase = () => {
  return (
    <>
      <h2>Hvor gjelder forsikringen?</h2>
      <p>
        Forsikringen gjelder for behandling i Norge av Helseforetak som Avonova
        har inngått avtale med.
      </p>
    </>
  );
};

export default ApplyWhereSectionBase;
