const CoverSectionBase = () => {
  return (
    <>
      <h2>Hva omfatter forsikringen?</h2>
      <p>
        Forsikringen omfatter nedenstående punkter som følge av sykdom eller
        plager som har oppstått i forsikringsperioden.
      </p>
      <ul>
        <li>Helserådgivning og Behandlingsplanlegging</li>
        <li>Digital lege- og psykologtjeneste</li>
        <li>Konsultasjon hos legespesialist</li>
        <li>Operasjon og sykehusbehandling</li>
        <li>6 behandlinger hos psykolog</li>
        <li>6 behandlinger hos fysioterapeut/kiropraktor/naprapat</li>
        <li>Etterbehandling og medisinsk rehabilitering</li>
        <li>Rehabiliteringsplan</li>
        <li>Second opinion – ny medisinsk vurdering</li>
      </ul>
    </>
  );
};

export default CoverSectionBase;
