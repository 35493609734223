const ApplyToWhoSection = () => {
  return (
    <>
      <h2>Hvem gjelder forsikringen for?</h2>
      <p>
        Forsikringen gjelder for deg som bedriften og grupperepresentanten har
        tegnet helseforsikringen for og som er fullt arbeidsfør, mellom 18 og 70
        år og er folkeregistrert i Norge. Om du har til hensikt å oppholde deg i
        utlandet lenger enn tre (3) måneder, eller om du flytter fra Norge, skal
        din bedrift melde dette til oss snarest, og du omfattes ikke lenger av
        forsikringen.
      </p>
    </>
  );
};

export default ApplyToWhoSection;
